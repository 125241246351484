<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <data-table
      :data-source="sortedChirurgies"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          @click.prevent="initForm"
          data-toggle="modal"
          data-target="#add-chirurgie"
          v-if="canAdd"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
    <modal
      id="add-chirurgie"
      title="Ajouter une intervention chirurgicale"
      size="x-large"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="adding"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <animal-detail :show-detail="true" />
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="categorie_chirurgie">Categorie Chirurgie</label>
              <select
                v-model="faible"
                class="form-control"
                id="categorie_chirurgie"
                name="categorie_chirurgie"
                v-select="{placeholder: 'Sélectionnez votre categorie de chirurgie'}"
              >
                <option value="" />
                <option
                  v-for="(c,i) in categorieChirurgies"
                  :value="c.value"
                  :key="i"
                >
                  {{ c.libelle }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="type">Type de chirurgie</label>
              <select
                class="form-control"
                id="type"
                name="type"
                v-model="type"
                v-select="{placeholder: 'Selectionnez le type de chirurgie'}"
              >
                <option value="" />
                <option
                  v-for="t in filtredChirurgies"
                  :value="t.uid"
                  :key="t.uid"
                >
                  {{ t.libelle }}
                </option>
              </select>
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="checkbox checkbox-outline checkbox-outline-bold checkbox-primary">
                <input
                  type="checkbox"
                  name="hospitalisation"
                  v-model="hospitalisation"
                >
                <span />Hospitalisation Post chirurgicale</label>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="duration">Durée de l'hospitalisation (Jours)</label>
              <input
                type="number"
                name="duration"
                id="duration"
                v-model="duration"
                class="form-control"
              >
            </div>
          </div>
        </div>
        
        <div class="form-group">
          <label for="add-info">Information Supplementaires</label>
          <textarea
            name="add-info"
            id="add-info"
            rows="5"
            class="form-control"
            v-model="info"
          />
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
            :class="loading? 'disabled': ''"
          >
            <span
              class="spinner-border m-1"
              role="status"
              v-if="loading"
            /> 
            <span v-else> Ajouter </span>
          </button>
        </div>
      </form>
    </modal>

    <modal
      id="update-chirurgie"
      title="Modifier une intervention chirurgicale"
      size="x-large"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editing"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <animal-detail 
            :show-detail="true" 
            :animal-using="selectedAnimal"
            v-if="selectedAnimal !== null" 
          />
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="update_categorie_chirurgie">Categorie Chirurgie</label>
              <select
                v-model="faible"
                class="form-control"
                id="update_categorie_chirurgie"
                name="update_categorie_chirurgie"
                v-select="{placeholder: 'Sélectionnez votre categorie de chirurgie'}"
              >
                <option value="" />
                <option
                  v-for="(c,i) in categorieChirurgies"
                  :value="c.value"
                  :key="i"
                >
                  {{ c.libelle }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="update_type">Type de chirurgie</label>
              <select
                class="form-control"
                id="update_type"
                name="update_type"
                v-model="type"
                v-select="{placeholder: 'Selectionnez le type de chirurgie'}"
              >
                <option value="" />
                <option
                  v-for="t in filtredChirurgies"
                  :value="t.uid"
                  :key="t.uid"
                >
                  {{ t.libelle }}
                </option>
              </select>
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="checkbox checkbox-outline checkbox-outline-bold checkbox-primary">
                <input
                  type="checkbox"
                  name="update_hospitalisation"
                  v-model="hospitalisation"
                >
                <span />Hospitalisation Post chirurgicale</label>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="update_duration">Durée de l'hospitalisation (Jours)</label>
              <input
                type="number"
                name="update_duration"
                id="update_duration"
                v-model="duration"
                class="form-control"
              >
            </div>
          </div>
        </div>
        
        <div class="form-group">
          <label for="update_info">Information Supplementaires</label>
          <textarea
            name="update_info"
            id="update_info"
            rows="5"
            class="form-control"
            v-model="info"
          />
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
            :class="loading? 'disabled': ''"
          >
            <span
              class="spinner-border m-1"
              role="status"
              v-if="loading"
            /> 
            <span v-else> Enregistrer </span>
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import moment from 'moment'
import navbar from '../../../components/navbar.vue'
  import DataTable from '../../../components/dataTable/local.vue'
  import {  DATE_TYPE, COMPONENT_TYPE, TEXT_TYPE, NUMERIC_TYPE} from '../../../components/dataTable/dataType'
  import AnimalDetail from '../../../components/identification/animal/detailInform.vue'
  import Modal from '../../../components/modal.vue'
  const Animal = () => import('../../../components/sanitaire/animal.vue')
  const CreatedBy = () => import('../../../components/sanitaire/createdBy.vue')
  const Type = () => import('../../../components/sanitaire/chirurgie/type.vue')
  
  const Actions = () => import('../../../components/sanitaire/chirurgie/actions.vue')
  import '../../../assets/css/forms/checkbox-theme.css'
import { ADD_CHIRURGIE, UPDATE_CHIRURGIE } from '../../../graphql/sanitaire'
export default {
    components: {navbar, DataTable, Modal, AnimalDetail },
      data(){
          return {
            navbarItems: [
              {
                  libelle: 'Sanitaire'
              },
              {
                libelle: 'Chirurgie',
              }
            ],
            pageIcon: 'la-cut',
            pageTitle: 'Chirurgie',
            pageDescription: 'Liste des interventions chirurgicales',
            has_error: false,
            error_msg: null,
            type: null,
            hospitalisation: false,
            duration: 0,
            info: null,
            faible: null,
            filtredChirurgies: [],
            
          }
      },
      watch: {
        faible(){
          this.filtredChirurgies = []
          if(this.faible !== null){
            this.filtredChirurgies = [...this.types].filter(item => item = item.faible === this.faible)
          }
        },
        selectedObject: {
          handler(){
            if(this.selectedObject !== null){
              this.setSelectedAnimal(this.selectedObject.animal)
              this.type = this.selectedObject.type
              this.duration = this.selectedObject.duration
              this.hospitalisation = this.selectedObject.hospitalisation
              this.info = this.selectedObject.info
              let typeChirurgie = this.types.find(item => item.uid === this.selectedObject.type)
              if(typeChirurgie) this.faible = typeChirurgie.faible
            }else{
              this.initForm()
            }
          },
          deep: true
        }
      },
      methods: {
          ...mapMutations({
              setSelectedAnimal: 'identification/SET_SELECTED_ANIMAL',
              done: 'DONE',
              setLoading: 'SET_LOADING'
          }),
          initForm(){
            this.setLoading(false)
            this.setSelectedAnimal(null)
            this.faible = null
            this.type = null
            this.info = null
            this.duration = null
            this.hospitalisation = false
          },
          adding() {
            this.setLoading(true)
            let data = {
                animal: this.selectedAnimal,
                type: this.type,
                hospitalisation: this.hospitalisation,
                duration: parseInt(this.duration),
                info: this.info
            }
            this.$apollo.mutate({
                mutation: ADD_CHIRURGIE,
                variables: {
                    "chirurgie": {
                        ...data
                    }
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Chirurugie sur ${this.selectedAnimal} add successfully`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                this.setLoading(false)
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
          },
          editing(){
            this.setLoading(true)
            let data = {
                animal: this.selectedAnimal,
                type: this.type,
                hospitalisation: this.hospitalisation,
                duration: parseInt(this.duration),
                info: this.info
            }
            this.$apollo.mutate({
                mutation: UPDATE_CHIRURGIE,
                variables: {
                    "chirurgie": {
                        ...data
                    },
                    "uid": this.selectedObject.uid
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Chirurugie sur ${this.selectedAnimal} add successfully`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                this.setLoading(false)
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
          }
      },
      computed: {
          ...mapGetters({
              selectedAnimal: 'identification/selectedAnimal',
              index: 'sanitaire/indexMedicaments',
              chirurgies: 'sanitaire/chirurgies',
              types: 'sanitaire/typeChirurgies',
              is_super_admin: 'auth/is_super_admin',
              is_veterinaire: 'auth/is_veterinaire',
              is_assistant_veterinaire: 'auth/is_assistant_veterinaire',
              is_assistant_veto_major: 'auth/is_assistant_veto_major',
              loading: 'loading',
              selectedObject: 'selectedObject'
          }),
          sortedChirurgies(){
              return [...this.chirurgies].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
          },
          categorieChirurgies(){
            return [
              {libelle: 'Petite Chirurgie', value: true},
              {libelle: 'Chirurgie lourde', value: false}
            ]
          },
          headers(){
              return [
                  {label: 'ID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                  {label: 'Animal', name: 'animal', type: COMPONENT_TYPE, component: Animal},
                  {label: 'Type d\'intervention', name: 'type', type: COMPONENT_TYPE, component: Type},
                  {label: 'Durée hospitalisation (Jrs)', name: 'duration', type: NUMERIC_TYPE, sortable: true},
                  {label: 'Intervenant', name: 'createBy', type: COMPONENT_TYPE, component: CreatedBy},
                  {label: 'Date d\'intervention', name: 'createdAt', type: DATE_TYPE, sortable: true},
                  {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
              ]
          },
          canAdd(){
            return this.is_super_admin ||
              this.is_veterinaire ||
              this.is_assistant_veterinaire ||
              this.is_assistant_veto_major
          }
      }
}
</script>

<style>

</style>